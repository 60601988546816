import React, { useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import Tooltip from '@material-ui/core/Tooltip'
import RemoveTourPointAlertDialog from '../RemoveTourPointAlertDialog'
import useApi from 'hooks/useApi'
import { useNavigate } from 'react-router'

const TourPointItem = ({ value, draggable, selected }) => {
  const [alertOpen, setAlertOpen] = useState(false)
  const navigate = useNavigate()
  const api = useApi()
  const { getLanguageField } = api
  const tour = api.tour.working

  const handleCancelAlert = () => {
    setAlertOpen(false)
  }

  const handleAcceptAlert = () => {
    console.log(selected)
    api.deleteTourPoint(value)
    if (selected) {
      console.log('we are selected')
      navigate('editor')
    }
    setAlertOpen(false)
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          background: selected ? 'LightBlue' : ''
        }}
        className='draggable-item'
        onClick={() => {
          api.selectTourPoint(value)
          navigate('point')
        }}
      >
        <div>
          {draggable && <b>&#9776;</b>}{' '}
          {getLanguageField(value.title).slice(0, 20)}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', paddingRight: 5 }}>
          <Tooltip title='Ta bort punkt'>
            <CloseIcon
              fontSize='small'
              onClick={(e) => {
                e.stopPropagation()
                setAlertOpen(true)
              }}
            />
          </Tooltip>
        </div>
      </div>
      <RemoveTourPointAlertDialog
        open={alertOpen}
        handleCancel={handleCancelAlert}
        handleAccept={handleAcceptAlert}
      />
    </>
  )
}

export default TourPointItem
