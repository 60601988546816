import React from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { PaperSoundSelect } from '../PaperComponents'
import { isSoundType } from '../../utils'
import useApi from 'hooks/useApi'

export const SFXPoint = ({ data, onSoundChange }) => {
  const api = useApi()
  const { tour, getLanguageField } = api
  return (
    <div>
      <PaperSoundSelect
        data={data}
        tourId={tour.working._id}
        sounds={tour.working.media.filter(isSoundType)}
        onSoundChange={onSoundChange}
      />
    </div>
  )
}
