import React, { useState, useEffect, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import GoogleMapContainer from 'gMaps'
import Button from '@material-ui/core/Button'
import useApi from 'hooks/useApi'

const mapW = 400
const mapH = 400

const TourEditorLocation = () => {
  const classes = useStyles()
  const api = useApi()
  const data = api.route.selected

  const onGetLocation = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      console.log('Latitude is :', position.coords.latitude)
      console.log('Longitude is :', position.coords.longitude)
      api.updateRouteLocation(
        [position.coords.longitude, position.coords.latitude],
        data
      )
    })
  }

  const onLatitudeChange = (e) => {
    api.updateRouteLocation(
      [data.point.coordinates[0], parseFloat(e.target.value)],
      data
    )
  }

  const onLongitudeChange = (e) => {
    api.updateRouteLocation(
      [parseFloat(e.target.value), data.point.coordinates[1]],
      data
    )
  }

  const onMapsMarkerDragEnd = useCallback(
    (coordinates) => {
      console.log(coordinates)
      const { latLng } = coordinates
      api.updateRouteLocation([latLng.lng(), latLng.lat()], data)
    },
    [api, data]
  )

  return (
    <div className={classes.r1R}>
      <div>
        <h3>Rundans startpunkt:</h3>
        <GoogleMapContainer
          latitude={
            data.point.coordinates[1] === 0 ? 62.89 : data.point.coordinates[1]
          }
          longitude={
            data.point.coordinates[0] === 0 ? 15.58 : data.point.coordinates[0]
          }
          onMarkerDragEnd={onMapsMarkerDragEnd}
          width={mapW}
          height={mapH}
          zoom={data.point.coordinates[1] === 0 ? 4 : undefined}
        />
        <div className={classes.underMap}>
          <Button
            onClick={() => onGetLocation()}
            variant='contained'
            color='primary'
          >
            Use Current Location
          </Button>
          <h3>Latitude:</h3>
          <input
            className={classes.singleLineTxtField}
            type='text'
            id='lat'
            name='lat'
            value={data.point.coordinates[1]}
            onChange={onLatitudeChange}
          />
          <h3>Longitude:</h3>
          <input
            className={classes.singleLineTxtField}
            type='text'
            id='lng'
            name='lng'
            value={data.point.coordinates[0]}
            onChange={onLongitudeChange}
          />
        </div>
      </div>
    </div>
  )
}
const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    //flexDirection: 'column',
    width: '100%'
  },
  r1: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flexFlow: 'column wrap'
  },
  r1L: {
    flex: `calc(100% - ${mapW}px - 10px)`,
    marginRight: 20
  },
  r1R: {
    flex: mapW,
    marginTop: 0,
    marginRight: '0px',
    flexDirection: 'column'
  },
  underMap: {
    marginTop: mapH + 20,
    width: '100%'
  },
  singleLineTxtField: {
    width: '100%',
    marginLeft: 0,
    fontSize: 20,
    paddingTop: 5
  },
  mGrid: {
    width: '30%',
    marginRight: 20,
    marginLeft: 20,
    backgroundColor: '#009900'
  },
  mLeft: {},
  mPaper: {
    marginBottom: 20,
    padding: 20,
    paddingTop: 5
  },
  publishOrDelete: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10
  }
})

export default TourEditorLocation
