import React from 'react'
import {
  PaperEditor,
  PaperImageSelect,
  PaperSoundSelect,
  PaperVideoSelect,
  PaperImage360Select
} from '../PaperComponents'
import { isImageType, isSoundType, isVideoType } from '../../utils'
import useApi from 'hooks/useApi'

export const TourPoint = ({
  data,
  onImageChange,
  onImage360Change,
  onSoundChange,
  onVideoChange
}) => {
  const api = useApi()
  const { tour, getLanguageField } = api
  console.log({ data })

  return (
    <div>
      <PaperEditor data={data} />
      <PaperImageSelect
        data={data}
        tourId={tour.working._id}
        images={tour.working.media.filter(isImageType)}
        onImageChange={onImageChange}
      />
      <PaperImage360Select
        data={data}
        tourId={tour.working._id}
        images={tour.working.media.filter(isImageType)}
        onImageChange={onImage360Change}
      />
      <PaperSoundSelect
        data={data}
        tourId={tour.working._id}
        sounds={tour.working.media.filter(isSoundType)}
        onSoundChange={onSoundChange}
      />
      <PaperVideoSelect
        data={data}
        tourId={tour.working._id}
        videos={tour.working.media.filter(isVideoType)}
        onVideoChange={onVideoChange}
      />
    </div>
  )
}
