import React, { useState, useEffect } from 'react'
import gjv from 'geojson-validation'
import PathPointsDialog from './PathPointsDialog'
import GoogleMapContainer from 'gMaps'
import { makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Paper from '@material-ui/core/Paper'
import { Container, Draggable } from 'react-smooth-dnd'
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Button from '@material-ui/core/Button'
import useDebounce from 'hooks/useDebounce'
import { isImageType } from 'utils'
import useApi from 'hooks/useApi'
import { PaperImageSelect } from '../PaperComponents'

const mapW = 400
const mapH = 400

const TourPathEditor = () => {
  const classes = useStyles()
  const [openPointsDialog, setOpenPointsDialog] = useState(false)
  const api = useApi()
  const { data } = api.tour
  console.log(data)
  const { tour, getLanguageField } = api

  const initialState = {
    editorState: EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(getLanguageField(data?.description))
      )
    ),
    dirty: false
  }

  const onClosePointsDialog = (points) => () => {
    const pointIds = points.map((i) => i._id)
    api.updateTourPathPoints(pointIds, data)
    setOpenPointsDialog(false)
  }

  const onCancelPointsDialog = () => {
    setOpenPointsDialog(false)
  }

  let fileReader

  const [state, setState] = useState(initialState)

  const debouncedEditorSave = useDebounce(state, 300)

  useEffect(() => {
    setState({
      ...state,
      editorState: EditorState.moveFocusToEnd(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(getLanguageField(data.description))
          )
        )
      ),
      dirty: false
    })
  }, [data._id])

  useEffect(() => {
    if (debouncedEditorSave && state.dirty === true) {
      const description = stateToHTML(
        debouncedEditorSave.editorState.getCurrentContent()
      )
      api.updateTourPathDescription(description, data)
      setState({ ...state, dirty: false })
    }
  }, [debouncedEditorSave])

  const onEditorStateChange = (editorState) => {
    if (editorState._immutable.lastChangeType != null) {
      setState({
        ...state,
        editorState,
        dirty: true
      })
    }
  }

  const reorderTourObjects = (e) => {
    const tourObjects = [...data.tourObjects]

    const isLast = e.removedIndex === tourObjects.length

    const removedObject = tourObjects[e.removedIndex]

    const bottom = tourObjects.slice(0, e.removedIndex)

    const top = tourObjects.slice(isLast ? [] : e.removedIndex + 1)

    const pre = [...bottom, ...top]

    const newArr = [
      ...pre.slice(0, e.addedIndex),
      removedObject,
      ...pre.slice(e.addedIndex)
    ]

    api.updateTourPathPoints(newArr, data)
  }

  const setOnMediaChange = (field) => (value) => {
    api.updateTourPathImage(field, value, data)
  }

  const onImageChange = (image) => api.updateTourPathImage(image?._id, data)

  const onTitleChange = (e) => {
    api.updateTourPathTitle(e.target.value, data)
  }

  const onSimpleFieldChange = (field) => (e) => {
    api.updateTourPathSimpleField(field, e.target.value, data)
  }

  const onSimpleFieldCheckboxChange = (field) => (e) => {
    api.updateTourPathSimpleField(field, e.target.checked, data)
  }

  const onIlluminatedChange = onSimpleFieldCheckboxChange('illuminated')
  const onAccesibleChange = onSimpleFieldCheckboxChange('accesible')
  const onPublishedChange = onSimpleFieldCheckboxChange('published')

  const onCategoryChange = onSimpleFieldChange('category')
  const onSubCategoryChange = (e) => {
    api.updateTourPathSubCategory(e.target.value, data)
  }
  const onTimeChange = onSimpleFieldChange('time')
  const onLengthChange = onSimpleFieldChange('length')

  // const onMapsMarkerDragEnd = useCallback(
  //   (coordinates) => {
  //     const { latLng } = coordinates
  //     api.updateTourPointLocation(latLng, data)
  //   },
  //   [data]
  // )

  const autoCenterMap = ({ google }, map) => {
    const features = map.data.addGeoJson(data.path)
    map.data.setStyle({
      draggable: true
    })
  }

  const handleFileRead = (e) => {
    const content = JSON.parse(fileReader.result)
    if (gjv.valid(content)) {
      console.log('this is valid GeoJSON!')
      api.updateTourPathGeoJSON(content, data)
    }

    console.log(content)
  }

  const handleFileChosen = (file) => {
    fileReader = new FileReader()
    fileReader.onloadend = handleFileRead
    fileReader.readAsText(file)
  }

  return (
    <div>
      <div className={classes.main}>
        <PathPointsDialog
          open={openPointsDialog}
          tourObjects={data.tourObjects}
          onCancel={onCancelPointsDialog}
          onClose={onClosePointsDialog}
        />
        <div className={classes.r1}>
          <div className={classes.r1L}>
            <FormControlLabel
              control={
                <Switch
                  checked={data.illuminated}
                  onChange={onIlluminatedChange}
                  name='illuminated'
                  color='primary'
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label='Illuminated'
            />
            <FormControlLabel
              control={
                <Switch
                  checked={data.accesible}
                  onChange={onAccesibleChange}
                  name='accesible'
                  color='primary'
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label='Accesible'
            />
            <FormControlLabel
              control={
                <Switch
                  checked={data.published}
                  onChange={onPublishedChange}
                  name='published'
                  color='primary'
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label='Published'
            />
            <Paper elevation={1} className={classes.mPaper}>
              <h3>Titel:</h3>
              <input
                className={classes.singleLineTxtField}
                type='text'
                id='title'
                name='title'
                value={getLanguageField(data.title)}
                onChange={onTitleChange}
              />
            </Paper>
            <Paper elevation={1} className={classes.mPaper}>
              <h3>Text:</h3>
              <Editor
                toolbar={{
                  options: ['inline', 'list', 'fontSize'],
                  inline: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ['bold', 'italic', 'underline']
                  }
                }}
                toolbarClassName='toolbarClassName'
                wrapperClassName='home-wrapper'
                editorClassName='home-editor'
                editorState={state.editorState}
                onEditorStateChange={onEditorStateChange}
                defaultValue={getLanguageField(data.description)}
              />
            </Paper>
            <Paper elevation={1} className={classes.mPaper}>
              <h3>Bild</h3>
              <div>
                <PaperImageSelect
                  data={data}
                  tourId={tour.working._id}
                  images={tour.working.media.filter(isImageType)}
                  onImageChange={onImageChange}
                  multi={false}
                />
              </div>
            </Paper>
            <h3>Length:</h3>
            <input
              className={classes.singleLineTxtField}
              type='text'
              id='length'
              name='length'
              value={data.length}
              onChange={onLengthChange}
            />
            <h3>Time:</h3>
            <input
              className={classes.singleLineTxtField}
              type='text'
              id='time'
              name='time'
              value={data.time}
              onChange={onTimeChange}
            />
          </div>
          <div className={classes.r1R}>
            <GoogleMapContainer
              width={mapW}
              height={mapH}
              onMarkerDragEnd={() => {}}
              disableMarker={true}
              onReady={autoCenterMap}
              zoom={12}
              key={data?._id}
            />
            <div className={classes.underMap}>
              <label>Ladda upp geojson: </label>
              <input
                type='file'
                accept='.json'
                onChange={(e) => handleFileChosen(e.target.files[0])}
              />
            </div>
            <Paper
              elevation={1}
              className={classes.mPaper}
              style={{ marginTop: 20 }}
            >
              <div style={{ margin: 15 }}>
                <Button
                  variant='contained'
                  onClick={() => setOpenPointsDialog(true)}
                >
                  Lägg till punkter i slingan
                </Button>
                <Container
                  style={{
                    marginTop: 10,
                    padding: 10
                  }}
                  onDrop={(e) => reorderTourObjects(e)}
                >
                  {data?.tourObjects?.map((value, index) => (
                    <Draggable
                      key={value}
                      style={{
                        borderBottomStyle: 'solid',
                        borderColor: 'grey',
                        borderWidth: 1
                      }}
                    >
                      {getLanguageField(
                        tour.working.places.find((i) => i._id === value).title
                      ).slice(0, 20)}
                    </Draggable>
                  ))}
                  <div style={{ marginTop: 40, fontSize: 10 }}>
                    * Drag points to change the order
                  </div>
                </Container>
              </div>
            </Paper>
          </div>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    //flexDirection: 'column',
    width: '100%'
  },
  r1: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flexFlow: 'column wrap'
  },
  r1L: {
    flex: `calc(100% - ${mapW}px - 10px)`,
    marginRight: 20
  },
  r1R: {
    flex: mapW,
    marginTop: 0,
    marginRight: '0px',
    flexDirection: 'column'
  },
  underMap: {
    marginTop: mapH + 20,
    width: '100%'
  },
  singleLineTxtField: {
    width: '100%',
    marginLeft: 0,
    fontSize: 20,
    paddingTop: 5
  },
  mGrid: {
    width: '30%',
    marginRight: 20,
    marginLeft: 20,
    backgroundColor: '#009900'
  },
  mLeft: {},
  mPaper: {
    marginBottom: 20,
    padding: 20,
    paddingTop: 5
  },
  mDropper: {
    width: '80%',
    marginTop: 10,
    marginBottom: 10,
    padding: 20,
    paddingTop: 5,
    fontSize: 16
  }
})

export default TourPathEditor
