import React, { useState, useContext, useEffect } from 'react'
import { RouteContext, TourContext, AccountContext } from 'App'
import { ShuttleApi, mediaUrl } from 'config'
import { groupBy } from 'lodash'
import axios from 'axios'
import { partitionArray } from '../utils'
import { isSFXPoint, isInfoPoint, isViaPoint, isTourPoint } from '../utils'
import {
  SELECT_TOUR_PATH,
  SELECT_TOUR_POINT,
  UPDATE_ACCOUNT_FROM_SERVER
} from '../symbols'
import {
  UPDATE_LANGUAGES,
  UPDATE_TOURPOINT,
  CHANGE_TOURPOINT_TYPE,
  REORDER_TOURPOINTS,
  REMOVE_TOURPOINT,
  CREATE_TOURPOINT,
  CREATE_TOUR_PATH,
  UPDATE_TOUR_PATH,
  REMOVE_TOUR_PATH,
  REORDER_TOUR_PATH,
  RESET_TOUR_STATE,
  UPDATE_TOUR_FROM_SERVER
} from '../symbols'
import { TOUR_MEDIA } from '../symbols'
import {
  SELECT_ROUTE,
  DESELECT_ROUTE,
  REMOVE_ROUTE,
  CREATE_ROUTE,
  UPDATE_ROUTE,
  RESET_ROUTES_STATE,
  UPDATE_ROUTES_FROM_SERVER
} from '../symbols'
import useAccount from './useAccount'

axios.defaults.baseURL = ShuttleApi
axios.defaults.withCredentials = true

const convertContentTypeGroupToUrlPart = (type) => {
  switch (type) {
    case 'IMAGE':
      return 'images'
    case 'SOUND':
      return 'sounds'
    case 'VIDEO':
      return 'videos'
    default:
      return null
  }
}

const getMediaUrlFromId = (id, tour) => {
  console.log('in getMediaUrlFromId')
  if (!id) return ''
  const media = getMediaFromId(id, tour)
  console.log({ media })
  if (!media) return ''
  const type = convertContentTypeGroupToUrlPart(media?.contentTypeGroup)
  const mediaUrls = getMediaUrls(tour._id)

  const path = mediaUrls[`${type}`]
  const url = `${path}${media._id}.${media.ext}`
  console.log({ url })
  return url
}

const getMediaFromId = (id, tour) => {
  console.log({ id })
  console.log({ tour })
  console.log({ ...tour.media })
  return tour.media?.find((i) => i._id === id)
}

const getMediaUrls = (tourId) => {
  const base = `${mediaUrl}/${tourId}`
  return {
    base,
    images: `${base}/images/`,
    sounds: `${base}/sounds/`,
    videos: `${base}/videos/`
  }
}

const reorderPlaces = (arr) => {
  const { withoutOrder = [], unordered = [] } = groupBy(arr, (v) =>
    v.ordernumber === 0 ? 'withoutOrder' : 'unordered'
  )

  const ordered = unordered
    .sort((a, b) => a.ordernumber - b.ordernumber)
    .map((v, i) => {
      return { ...v, ordernumber: i + 1 }
    })
  return withoutOrder.concat(ordered)
}

const deleteOrderedElement = (id, arr) => {
  const newArr = [...arr]
  const item = newArr.find((i) => i._id === id)
  console.log(item)
  return newArr
    .filter((i) => i._id !== id)
    .map((i) => {
      if (i.order > item.order) return { ...i, order: i.order - 1 }
      return i
    })
}

const removeMedia = async (id, tour) => {
  try {
    await axios.post('/api/media/remove', {
      mediaId: id,
      tourId: tour.working._id
    })
    await getTour(tour.working._id)
  } catch (e) {}
}

const reorderItems = (indices, orderedArray) => {
  const removeIndex = indices?.removedIndex
  const addIndex = indices?.addedIndex
  const places = orderedArray.map((i) => {
    // if indices are empty, null or undefined then map, sort and fix eventual
    // holes in order
    if (!indices) {
      const newArr = [...orderedArray]
      newArr
        .sort((a, b) => a.order - b.order)
        .filter((n, nidx) => console.log(n, nidx, n.order))
      return i
    }

    // if outside both addIndex and removeIndex nothing changes
    if (
      (i.order < addIndex && i.order < removeIndex) ||
      (i.order > addIndex && i.order > removeIndex)
    ) {
      return i
    }

    // empty move
    if (addIndex === removeIndex) {
      return i
    }

    // moving item towards end of list
    if (addIndex > removeIndex) {
      if (i.order === removeIndex) {
        return { ...i, order: addIndex }
      }
      if (i.order <= addIndex) {
        return { ...i, order: i.order - 1 }
      }
    }

    // moving item towards start of list
    if (addIndex < removeIndex) {
      if (i.order === removeIndex) {
        return { ...i, order: addIndex }
      }
      if (i.order >= addIndex) {
        return { ...i, order: i.order + 1 }
      }
    }
  })
  return places
}

const getLanguage = (route, language_code) => {
  return language_code
    ? route.selected.languages.find((i) => i.language_code === language_code)
    : route.selected.languages.find((i) => i.primary)
}

const updateLanguageField = (route, arr = [], data) => {
  const primary = getLanguage(route)
  const { language_code } = primary
  const exists = arr.find((i) => i.language_code === language_code)
  if (exists) {
    return arr.map((i) =>
      i.language_code === language_code ? { ...i, text: data } : i
    )
  } else {
    return [
      {
        translate: false,
        language_code,
        direction: primary.direction,
        text: data
      }
    ].concat(arr)
  }
}

const getLanguageField = (route, field, lang) => {
  const { language_code } = getLanguage(route, lang)
  return field?.data?.find((i) => i.language_code === language_code)?.text ?? ''
}

const getTour = async (id, dispatchTour) => {
  console.log('calling getTour')
  console.log(id)
  console.trace()
  await axios
    .get(`${ShuttleApi}/api/tour/${id}`)
    .then((res) => {
      console.log(res.data)
      dispatchTour({
        type: UPDATE_TOUR_FROM_SERVER,
        payload: {
          original: res.data,
          working: { ...res.data },
          dirty: false
        }
      })
    })
    .catch(/*() => setObjects({ hasErrors: true })*/)
}

const createTourPoint = (tourpoint, dispatchTour) => {
  dispatchTour({ type: CREATE_TOURPOINT, payload: tourpoint })
}

const createAndSelectTourPoint = (tourpoint, dispatchTour) => {
  dispatchTour({ type: CREATE_TOURPOINT, payload: tourpoint })
  dispatchTour({ type: SELECT_TOUR_POINT, payload: tourpoint })
}

const deleteTourPoint = (tourpoint, dispatchTour) => {
  dispatchTour({ type: REMOVE_TOURPOINT, payload: tourpoint })
}

const selectTourPoint = (tourpoint, dispatchTour) => {
  dispatchTour({ type: SELECT_TOUR_POINT, payload: tourpoint })
}

const reorderTourPoint = (event, dispatchTour) => {
  dispatchTour({ type: REORDER_TOURPOINTS, payload: event })
}

const updateTourPointTitle = (title, tourpoint, dispatchTour, route) => {
  dispatchTour({
    type: UPDATE_TOURPOINT,
    payload: {
      ...tourpoint,
      title: {
        dirty: true,
        data: updateLanguageField(route, tourpoint.title.data, title)
      }
    }
  })
}

const updateTourPointSubCategory = (
  subcategory,
  tourpoint,
  dispatchTour,
  route
) => {
  dispatchTour({
    type: UPDATE_TOURPOINT,
    payload: {
      ...tourpoint,
      subcategory: {
        dirty: true,
        data: updateLanguageField(
          route,
          tourpoint.subcategory.data,
          subcategory
        )
      }
    }
  })
}

const updateTourPointDescription = (
  description,
  tourpoint,
  dispatchTour,
  route
) => {
  dispatchTour({
    type: UPDATE_TOURPOINT,
    payload: {
      ...tourpoint,
      htmlContent: {
        dirty: true,
        data: updateLanguageField(
          route,
          tourpoint.htmlContent.data,
          description
        )
      }
    }
  })
}

const updateTourPointMedia = (mediaType, media, tourpoint, dispatchTour) => {
  dispatchTour({
    type: UPDATE_TOURPOINT,
    payload: {
      ...tourpoint,
      [`${mediaType}`]: media == null ? [] : [media].flat()
    }
  })
}

const updateTourPointSimpleField = (field, value, tourpoint, dispatchTour) => {
  dispatchTour({
    type: UPDATE_TOURPOINT,
    payload: {
      ...tourpoint,
      [`${field}`]: value
    }
  })
}

const updateTourPointLocation = (location, tourpoint, dispatchTour) => {
  dispatchTour({
    type: UPDATE_TOURPOINT,
    payload: {
      ...tourpoint,
      point: { ...tourpoint.point, coordinates: location }
    }
  })
}

const updateTourPointNumPad = (number, tourpoint, dispatchTour) => {
  dispatchTour({
    type: UPDATE_TOURPOINT,
    payload: {
      ...tourpoint,
      numpadId: number || 0
    }
  })
}

const updateTourPointType = (type, tourpoint, dispatchTour) => {
  dispatchTour({
    type: CHANGE_TOURPOINT_TYPE,
    payload: {
      ...tourpoint,
      placeTypeId: type
    }
  })
}

const selectTourPath = (path, dispatchTour) => {
  dispatchTour({ type: SELECT_TOUR_PATH, payload: path })
}

const createAndSelectTourPath = (path, dispatchTour) => {
  dispatchTour({
    type: CREATE_TOUR_PATH,
    payload: {
      ...path
    }
  })
  dispatchTour({ type: SELECT_TOUR_PATH, payload: path })
}

const createTourPath = (path, dispatchTour) => {
  dispatchTour({
    type: CREATE_TOUR_PATH,
    payload: {
      ...path
    }
  })
}

const deleteTourPath = (path, dispatchTour) => {
  dispatchTour({ type: REMOVE_TOUR_PATH, payload: path })
}

const updateTourPathTitle = (title, path, dispatchTour, route) => {
  dispatchTour({
    type: UPDATE_TOUR_PATH,
    payload: {
      ...path,
      title: {
        dirty: true,
        data: updateLanguageField(route, path.title.data, title)
      }
    }
  })
}

const updateTourPathDescription = (description, path, dispatchTour, route) => {
  dispatchTour({
    type: UPDATE_TOUR_PATH,
    payload: {
      ...path,
      description: {
        dirty: true,
        data: updateLanguageField(route, path.description.data, description)
      }
    }
  })
}

const updateTourPathSubCategory = (subcategory, path, dispatchTour, route) => {
  dispatchTour({
    type: UPDATE_TOUR_PATH,
    payload: {
      ...path,
      subcategory: {
        dirty: true,
        data: updateLanguageField(route, path.subcategory.data, subcategory)
      }
    }
  })
}

const updateTourPathImage = (image, path, dispatchTour) => {
  dispatchTour({
    type: UPDATE_TOUR_PATH,
    payload: {
      ...path,
      image
    }
  })
}

const updateTourPathSimpleField = (field, value, path, dispatchTour) => {
  dispatchTour({
    type: UPDATE_TOUR_PATH,
    payload: {
      ...path,
      [`${field}`]: value
    }
  })
}

const updateTourPathGeoJSON = (geoJSON, path, dispatchTour) => {
  dispatchTour({
    type: UPDATE_TOUR_PATH,
    payload: {
      ...path,
      geoJSON
    }
  })
}

const updateTourPathPoints = (points, path, dispatchTour) => {
  dispatchTour({
    type: UPDATE_TOUR_PATH,
    payload: {
      ...path,
      tourObjects: points
    }
  })
}

const selectMediaType = (mediaType, dispatchTour) => {
  dispatchTour({ type: TOUR_MEDIA, payload: mediaType })
}

const viewMedia = (type, dispatchTour) => {
  dispatchTour({
    type: TOUR_MEDIA,
    payload: type
  })
}

const getRoutes = async (dispatchRoute) => {
  await axios
    .get(`${ShuttleApi}/api/routes`)
    .then((res) => {
      dispatchRoute({
        type: UPDATE_ROUTES_FROM_SERVER,
        payload: {
          original: { tours: res.data },
          working: { tours: res.data },
          dirty: false
        }
      })
    })
    .catch((e) => console.log(e))
}

const deleteRoute = async (id, dispatchRoute) => {
  try {
    const res = await axios.post(`${ShuttleApi}/api/route/delete`, { _id: id })
    await getRoutes(dispatchRoute)
    console.log(res)
  } catch (err) {
    console.log(err)
  }
}

const createRoute = async (dispatchRoute) => {
  try {
    const { data } = await axios.post(`${ShuttleApi}/api/route/create`, {})
    console.log(data)
    await getRoutes(dispatchRoute)
    selectRoute(data, dispatchRoute)
  } catch (err) {
    console.log(err)
  }
}

const selectRoute = (route, dispatchRoute) => {
  dispatchRoute({ type: SELECT_ROUTE, payload: route })
}

const deselectRoute = (dispatchRoute) => {
  dispatchRoute({ type: DESELECT_ROUTE, payload: {} })
}

const resetRoute = (dispatchRoute) => {
  dispatchRoute({ type: RESET_ROUTES_STATE, payload: {} })
}

const resetTour = (dispatchTour) => {
  dispatchTour({ type: RESET_TOUR_STATE, payload: {} })
}

const updateRouteDescription = (description, route, dispatchRoute) => {
  dispatchRoute({
    type: UPDATE_ROUTE,
    payload: {
      ...route.selected,
      htmlContent: {
        dirty: true,
        data: updateLanguageField(
          route,
          route.selected.htmlContent.data,
          description
        )
      }
    }
  })
}

const updateRouteTitle = (title, route, dispatchRoute) => {
  dispatchRoute({
    type: UPDATE_ROUTE,
    payload: {
      ...route.selected,
      title: {
        dirty: true,
        data: updateLanguageField(route, route.selected.title.data, title)
      }
    }
  })
}

const updateRouteType = (type, route, dispatchRoute) => {
  dispatchRoute({
    type: UPDATE_ROUTE,
    payload: {
      ...route.selected,
      routeType: type
    }
  })
}

const updateRoutePublished = (published, route, dispatchRoute) => {
  dispatchRoute({
    type: UPDATE_ROUTE,
    payload: {
      ...route.selected,
      published
    }
  })
}

const updateRouteImage = (image, route, dispatchRoute) => {
  dispatchRoute({
    type: UPDATE_ROUTE,
    payload: {
      ...route.selected,
      coverImage: image ? image : null
    }
  })
}

const updateRouteToggleCode = (active, route, dispatchRoute) => {
  dispatchRoute({
    type: UPDATE_ROUTE,
    payload: {
      ...route.selected,
      code: {
        ...route.selected.code,
        active
      }
    }
  })
}

const updateRouteSimpleField = (field, value, route, dispatchRoute) => {
  dispatchRoute({
    type: UPDATE_ROUTE,
    payload: {
      ...route.selected,
      [`${field}`]: value
    }
  })
}

const updateRouteLocation = (location, route, dispatchRoute) => {
  dispatchRoute({
    type: UPDATE_ROUTE,
    payload: {
      ...route.selected,
      point: { ...route.selected.point, coordinates: location }
    }
  })
}

const updateRouteLanguages = (
  languages,
  dispatchTour,
  route,
  dispatchRoute
) => {
  dispatchRoute({
    type: UPDATE_ROUTE,
    payload: {
      ...route.selected,
      dirtyLanguage: true,
      languages
    }
  })
  dispatchTour({
    type: UPDATE_LANGUAGES,
    payload: {}
  })
}

const saveRoute = async (route, dispatchRoute) => {
  console.log('saveRoute called')
  const { data } = await axios.post(`${ShuttleApi}/api/routes`, route.selected)
  dispatchRoute({
    type: UPDATE_ROUTES_FROM_SERVER,
    payload: {
      original: { tours: data },
      working: { tours: data },
      selected: data.find((v) => v._id === route.selected._id),
      dirty: false
    }
  })
}

const saveTour = async (tour, dispatchTour, dispatchRoute) => {
  console.log('saveTour called')
  console.log(tour)
  const { data } = await axios.post(
    `${ShuttleApi}/api/tour/${tour.working._id}`,
    tour.working
  )
  dispatchTour({
    type: UPDATE_TOUR_FROM_SERVER,
    payload: {
      original: data,
      working: { ...data },
      dirty: false
    }
  })
  await getRoutes(dispatchRoute)
}

const updateTour = async (tour, dispatchTour, dispatchRoute) => {
  console.log(tour)
  const { data } = await axios.post(`${ShuttleApi}/api/tour/${tour._id}`, tour)
  dispatchTour({
    type: UPDATE_TOUR_FROM_SERVER,
    payload: {
      original: data,
      working: { ...data },
      dirty: false
    }
  })
  await getRoutes(dispatchRoute)
}

const useApi = () => {
  const account = useAccount()
  const { tour, dispatchTour } = useContext(TourContext)
  const { route, dispatchRoute } = useContext(RouteContext)
  // const { account, dispatchAccount } = useContext(AccountContext)

  return {
    route,
    tour,
    ...account,
    createRoute: async () => await createRoute(dispatchRoute),
    deleteRoute: async (id) => await deleteRoute(id, dispatchRoute),
    getRoutes: async () => await getRoutes(dispatchRoute),
    selectRoute: (route) => selectRoute(route, dispatchRoute),
    deselectRoute: () => deselectRoute(dispatchRoute),
    resetRoute: () => resetRoute(dispatchRoute),
    getTour: async () => await getTour(route.selected.tourId, dispatchTour),
    saveTour: async () => await saveTour(tour, dispatchTour, dispatchRoute),
    resetTour: () => resetTour(dispatchTour),
    createTourPath: (path) => createTourPath(path, dispatchTour),
    deleteTourPath: (path) => deleteTourPath(path, dispatchTour),
    createTourPoint: (tourpoint) => createTourPoint(tourpoint, dispatchTour),
    createAndSelectTourPoint: (tourpoint) =>
      createAndSelectTourPoint(tourpoint, dispatchTour),
    deleteTourPoint: (tourpoint) => deleteTourPoint(tourpoint, dispatchTour),
    selectTourPoint: (tourpoint) => selectTourPoint(tourpoint, dispatchTour),
    reorderTourPoint: (event) => reorderTourPoint(event, dispatchTour),
    getLanguageField: (field, lang) => getLanguageField(route, field, lang),
    updateTourPointDescription: (description, tourpoint) =>
      updateTourPointDescription(description, tourpoint, dispatchTour, route),
    updateTourPointTitle: (title, tourpoint) =>
      updateTourPointTitle(title, tourpoint, dispatchTour, route),
    updateTourPointSubCategory: (subcategory, tourpoint) =>
      updateTourPointSubCategory(subcategory, tourpoint, dispatchTour, route),
    updateTourPointMedia: (mediaType, media, tourpoint) =>
      updateTourPointMedia(mediaType, media, tourpoint, dispatchTour),
    updateTourPointSimpleField: (field, value, tourpoint) =>
      updateTourPointSimpleField(field, value, tourpoint, dispatchTour),
    updateTourPointLocation: (location, tourpoint) =>
      updateTourPointLocation(location, tourpoint, dispatchTour),
    updateTourPointNumPad: (number, tourpoint) =>
      updateTourPointNumPad(number, tourpoint, dispatchTour),
    updateTourPointType: (type, tourpoint) =>
      updateTourPointType(type, tourpoint, dispatchTour),
    selectTourPath: (path) => selectTourPath(path, dispatchTour),
    updateTourPathDescription: (description, path) =>
      updateTourPathDescription(description, path, dispatchTour, route),
    updateTourPathSubCategory: (subcategory, path) =>
      updateTourPathSubCategory(subcategory, path, dispatchTour, route),
    updateTourPathTitle: (title, path) =>
      updateTourPathTitle(title, path, dispatchTour, route),
    updateTourPathImage: (image, path) =>
      updateTourPathImage(image, path, dispatchTour),
    updateTourPathSimpleField: (field, value, path) =>
      updateTourPathSimpleField(field, value, path, dispatchTour),
    updateTourPathGeoJSON: (value, path) =>
      updateTourPathGeoJSON(value, path, dispatchTour),
    updateTourPathPoints: (value, path) =>
      updateTourPathPoints(value, path, dispatchTour),
    viewMedia: (type) => viewMedia(type, dispatchTour),
    updateTour: async (tour) =>
      await updateTour(tour, dispatchTour, dispatchRoute),
    saveRoute: async () => await saveRoute(route, dispatchRoute),
    updateRouteTitle: (title) => updateRouteTitle(title, route, dispatchRoute),
    updateRouteType: (type) => updateRouteType(type, route, dispatchRoute),
    updateRoutePublished: (published) =>
      updateRoutePublished(published, route, dispatchRoute),
    updateRouteDescription: (description) =>
      updateRouteDescription(description, route, dispatchRoute),
    updateRouteImage: (image) => updateRouteImage(image, route, dispatchRoute),
    updateRouteToggleCode: (active) =>
      updateRouteToggleCode(active, route, dispatchRoute),
    updateRouteSimpleField: (field, value) =>
      updateRouteSimpleField(field, value, route, dispatchRoute),
    updateRouteLocation: (location) =>
      updateRouteLocation(location, route, dispatchRoute),
    updateRouteLanguages: (languages) =>
      updateRouteLanguages(languages, dispatchTour, route, dispatchRoute),
    selectMediaType: (mediaType) => selectMediaType(mediaType, dispatchTour),
    removeMedia: async (id) => await removeMedia(id, tour),
    getMediaFromId: (id) => getMediaFromId(id, tour.working),
    getMediaUrls: () => getMediaUrls(tour.working?._id),
    getMediaUrlFromId: (id) => getMediaUrlFromId(id, tour.working)
  }
}

export default useApi
