import React, { useState, useEffect, useCallback } from 'react'
import Paper from '@material-ui/core/Paper'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { makeStyles } from '@material-ui/core/styles'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import Button from '@material-ui/core/Button'
import Select, { components } from 'react-select'
import { DeleteRouteAlertDialog } from './DeleteAlertDialogs'
import LanguageDialog from './LanguageDialog'
import TourEditorLocation from './TourEditorLocation'
import { mediaUrl } from 'config'
import useDebounce from 'hooks/useDebounce'
import { isImageType } from 'utils'
import useApi from 'hooks/useApi'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MSelect
} from '@material-ui/core'

const mapW = 400
const mapH = 400

const ImageValue = (props) => {
  const { data } = props

  const id = props.selectProps.selectProps.id
  const image = `${mediaUrl}/${id}/images/${data._id}.${data.ext}`
  return (
    <div style={{ height: '4rem' }}>
      <components.SingleValue {...props}>
        <div>
          <img style={{ width: '100px', height: '100px' }} src={image} alt='' />
        </div>
      </components.SingleValue>
    </div>
  )
}

const ImageOption = (props) => {
  const api = useApi()
  const id = props.selectProps.selectProps.id
  // const image = `${mediaUrl}/${id}/images/${props.data._id}.${props.data.ext}`
  const image = api.getMediaUrlFromId(props.data._id)
  return (
    <components.Option {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          style={{ width: '50px', height: '50px' }}
          src={image}
          alt={props.data.name}
        />
        <div style={{ paddingLeft: 50 }}>{props.data.name}</div>
      </div>
    </components.Option>
  )
}

const TourEditor = () => {
  const classes = useStyles()
  const api = useApi()
  const { tour, getLanguageField } = api
  const data = api.route.selected
  const initialState = {
    editorState: EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(getLanguageField(data.htmlContent))
      )
    ),
    dirty: false
  }

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [openLanguageDialog, setOpenLanguageDialog] = useState(false)
  const [state, setState] = useState(initialState)

  const debouncedEditorSave = useDebounce(state, 300)

  useEffect(() => {
    console.log('inside first use effect')
    setState({
      ...state,
      editorState: EditorState.moveFocusToEnd(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(getLanguageField(data.htmlContent))
          )
        )
      ),
      dirty: false
    })
  }, [data._id])

  useEffect(() => {
    if (debouncedEditorSave && state.dirty === true) {
      console.log('updating from editor')
      const description = stateToHTML(
        debouncedEditorSave.editorState.getCurrentContent()
      )
      api.updateRouteDescription(description)
      setState({ ...state, dirty: false })
    }
  }, [debouncedEditorSave])

  const onEditorStateChange = (editorState) => {
    if (editorState._immutable.lastChangeType != null) {
      console.log('we are in editorstate')
      console.log(stateToHTML(editorState.getCurrentContent()))
      setState({
        ...state,
        editorState,
        dirty: true
      })
    }
  }

  const onTitleChange = (e) => {
    api.updateRouteTitle(e.target.value)
  }

  const onImageChange = (value) => {
    api.updateRouteImage(value)
  }

  const onSimpleFieldChange = (field) => (e) => {
    api.updateRouteSimpleField(field, e.target.value)
  }

  const onPublishedChange = (e) => {
    api.updateRoutePublished(e.target.checked)
  }

  const onCodeSwitchChange = (e) => {
    api.updateRouteToggleCode(e.target.checked)
  }

  const onOpenDeleteRouteAlertDialog = () => setOpenDeleteDialog(true)

  const onDeleteRoute = async () => {
    api.deleteRoute(data._id)
  }

  const onPlaceChange = onSimpleFieldChange('area')
  const onRouteTimeChange = onSimpleFieldChange('time')
  const onRouteLengthChange = onSimpleFieldChange('length')

  const onCloseLanguageDialog = (languages) => () => {
    if (JSON.stringify(languages) !== JSON.stringify(data.languages)) {
      api.updateRouteLanguages(languages)
    }
    setOpenLanguageDialog(false)
  }

  const onCancelLanguageDialog = () => {
    setOpenLanguageDialog(false)
  }

  const imageOptions = tour.working.media?.filter(isImageType)

  return (
    <div className={classes.main}>
      <LanguageDialog
        open={openLanguageDialog}
        onCancel={onCancelLanguageDialog}
        onClose={onCloseLanguageDialog}
      />
      <DeleteRouteAlertDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        onDeleteRoute={onDeleteRoute}
      />
      <div className={classes.r1}>
        <div className={classes.r1L}>
          <div className={classes.publishOrDelete}>
            <FormControlLabel
              control={
                <Switch
                  checked={data.published}
                  onChange={onPublishedChange}
                  name='published'
                  color='primary'
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label='Publicera'
            />
            <FormControlLabel
              control={
                <Switch
                  checked={data.code?.active}
                  onChange={onCodeSwitchChange}
                  name='code'
                  color='primary'
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label={`Lås med kod: ${data.code?.number
                ?.toString?.()
                .padStart?.(6, '0')}`}
            />
            <Button
              variant='contained'
              color='primary'
              onClick={() => setOpenLanguageDialog(true)}
            >
              Språk
            </Button>
            <Button
              variant='contained'
              color='secondary'
              onClick={onOpenDeleteRouteAlertDialog}
              disabled
            >
              Radera runda
            </Button>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id='demo-simple-select-label'>Rund Typ</InputLabel>
              <MSelect
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={data.routeType}
                label='Typ'
                onChange={(e) => api.updateRouteType(e.target.value)}
              >
                {data.routeTypes.map((i) => (
                  <MenuItem key={i} value={i}>{`${i}`}</MenuItem>
                ))}
              </MSelect>
            </FormControl>
          </div>
          <Paper elevation={1} className={classes.mPaper}>
            <h3>Rundans titel:</h3>
            <input
              className={classes.singleLineTxtField}
              type='text'
              id='title'
              name='title'
              value={getLanguageField(data.title)}
              onChange={onTitleChange}
            />
          </Paper>
          <Paper elevation={1} className={classes.mPaper}>
            <h3>Introtext:</h3>
            <Editor
              toolbar={{
                options: ['inline', 'list', 'fontSize'],
                inline: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ['bold', 'italic', 'underline']
                }
              }}
              toolbarClassName='toolbarClassName'
              wrapperClassName='home-wrapper'
              editorClassName='home-editor'
              editorState={state.editorState}
              //value={data.htmlContent.sv}
              onEditorStateChange={onEditorStateChange}
              defaultValue={getLanguageField(data.htmlContent)}
            />
          </Paper>
          <Paper elevation={1} className={classes.mPaper}>
            <h3>Rundans bild:</h3>
            <div>
              <Select
                classNamePrefix='react-select'
                placeholder={'Välj bild...'}
                value={data.coverImage}
                selectProps={{ id: tour.working._id }}
                getOptionValue={(option) => option}
                //getLabelValue={(option) => option.name}
                components={{
                  Option: ImageOption,
                  SingleValue: ImageValue
                }}
                onChange={onImageChange}
                options={imageOptions}
                menuPlacement='auto'
                openMenuOnClick={true}
                closeMenuOnSelect={true}
                isSearchable={false}
                isClearable={true}
              />
            </div>
          </Paper>
          <Paper elevation={1} className={classes.mPaper}>
            <h3>Område:</h3>
            <input
              className={classes.singleLineTxtField}
              type='text'
              id='guideArea'
              name='guideArea'
              value={data.area}
              onChange={onPlaceChange}
            />
            <h3>Längd på rundan(km):</h3>
            <input
              className={classes.singleLineTxtField}
              type='text'
              id='guideLength'
              name='guideLength'
              value={data.length}
              onChange={onRouteLengthChange}
            />
            <h3>Tidsåtgång(minuter):</h3>
            <input
              className={classes.singleLineTxtField}
              type='text'
              id='guideTime'
              name='guideTime'
              value={data.time}
              onChange={onRouteTimeChange}
            />
          </Paper>
        </div>
        <TourEditorLocation />
      </div>
    </div>
  )
}

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    //flexDirection: 'column',
    width: '100%'
  },
  r1: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flexFlow: 'column wrap'
  },
  r1L: {
    flex: `calc(100% - ${mapW}px - 10px)`,
    marginRight: 20
  },
  r1R: {
    flex: mapW,
    marginTop: 0,
    marginRight: '0px',
    flexDirection: 'column'
  },
  underMap: {
    marginTop: mapH + 20,
    width: '100%'
  },
  singleLineTxtField: {
    width: '100%',
    marginLeft: 0,
    fontSize: 20,
    paddingTop: 5
  },
  mGrid: {
    width: '30%',
    marginRight: 20,
    marginLeft: 20,
    backgroundColor: '#009900'
  },
  mLeft: {},
  mPaper: {
    marginBottom: 20,
    padding: 20,
    paddingTop: 5
  },
  publishOrDelete: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10
  }
})

export default TourEditor
