import React from 'react'
import useApi from 'hooks/useApi'
import {
  PaperCategory,
  PaperSubCategory,
  PaperEditor,
  PaperImageSelect,
  PaperImage360Select
} from '../PaperComponents'
import { isImageType } from '../../utils'

export const PathPoint = ({
  data,
  onImageChange,
  onImage360Change,
  onCategoryChange,
  onSubCategoryChange
}) => {
  const api = useApi()
  const { tour, getLanguageField } = api
  const { placesCategories } = tour.working

  return (
    <div>
      <PaperEditor data={data} />
      <PaperImageSelect
        data={data}
        tourId={tour.working._id}
        images={tour.working.media.filter(isImageType)}
        onImageChange={onImageChange}
      />
      <PaperImage360Select
        data={data}
        tourId={tour.working._id}
        images={tour.working.media.filter(isImageType)}
        onImageChange={onImage360Change}
      />
      <PaperCategory
        data={data}
        categories={placesCategories}
        onCategoryChange={onCategoryChange}
      />
      <PaperSubCategory data={data} onSubCategoryChange={onSubCategoryChange} />
    </div>
  )
}
