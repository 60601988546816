import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

export const DeletePathAlertDialog = ({ open, setOpen, onDeletePath }) => {
  const handleClose = () => {
    setOpen(false)
  }

  const handleAccept = () => {
    setOpen(false)
    onDeletePath()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        {'Varning! - Radera slingang'}
      </DialogTitle>
      <DialogContent>
        Detta raderar hela slingan. Det går inte att ångra detta beslut.
        <DialogContentText id='alert-dialog-description'></DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Disagree
        </Button>
        <Button onClick={handleAccept} color='primary' autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export const DeleteRouteAlertDialog = ({ open, setOpen, onDeleteRoute }) => {
  const handleClose = () => {
    setOpen(false)
  }

  const handleAccept = () => {
    setOpen(false)
    onDeleteRoute()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        {'Varning! - Radera rundan'}
      </DialogTitle>
      <DialogContent>
        Detta raderar hela rundan och tar bort all media associerad med runden.
        Det går inte att ångra detta beslut.
        <DialogContentText id='alert-dialog-description'></DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Disagree
        </Button>
        <Button onClick={handleAccept} color='primary' autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  )
}
